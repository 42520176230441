import React, { useEffect, useContext } from 'react';
import ReactGA from 'react-ga4';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import Adams from 'web-shared-components/components/PortfolioTemplates/Themes/Adams';
import Moodie from 'web-shared-components/components/PortfolioTemplates/Themes/Moodie';
import Newton from 'web-shared-components/components/PortfolioTemplates/Themes/Newton';
import PortfolioMetaInfo from 'web-shared-components/components/MetaInfo/PortfolioMetaInfo';

import AppContext from '../AppContext';
import { THEMES, MODES } from '../lib/constants/portfolio';
import sendPortfolioMessage from '../Mutations/SendPortfolioMessage';

import BlackIcon from '../assets/black-icon.png';

const Portfolio = ({
  portfolio,
  domain,
  type,
}) => {
  const { theme } = portfolio;
  const location = useLocation();

  const appContext = useContext(AppContext);
  const { webpSupported } = appContext || {};

  useEffect(() => {
    if (portfolio?.analyticsCode) {
      ReactGA.initialize(portfolio.analyticsCode);
    }
  }, []);

  useEffect(() => {
    if (portfolio?.analyticsCode) {
      ReactGA.send({
        page: location.pathname,
        hitType: 'pageview',
      });
    }
  }, [location]);

  const sendMessage = type === 'theme' ? () => {} : (form, onCompleted, onError) => {
    const { email, message, name, subject } = form.values;
    sendPortfolioMessage({
      input: {
        domainName: domain,
        portfolioOwnerId: portfolio?.legacyId,
        subject,
        senderName: name,
        senderEmail: email,
        message,
      },
      onCompleted,
      onError,
    });
  };

  // TODO: Test once setting a custom cover photo is possible
  const imageURL = portfolio?.coverPhoto?.avatar?.images?.[0]?.url
    || portfolio?.coverPhoto?.photo?.images?.[0]?.url
    || portfolio?.coverPhoto?.images?.[0]?.url;

  // eslint-disable-next-line no-nested-ternary
  const themeColor = portfolio?.appearance === MODES.LIGHT ? '#FFFFFF'
    : portfolio?.appearance === MODES.DARK ? '#222222'
      : null;

  const renderPortfolioMetaInfo = (
    <PortfolioMetaInfo
      title={portfolio?.title || '500px Portfolio'}
      description={portfolio?.description || ''}
      icon={BlackIcon}
      imageURL={imageURL}
      themeColor={themeColor}
    />
  );

  switch (theme.name) {
    case THEMES.MOODIE:
      return (
        <>
          {renderPortfolioMetaInfo}
          <Moodie
            type={type}
            portfolio={portfolio}
            onSubmitMessage={sendMessage}
            webpSupported={webpSupported}
          />
        </>
      );
    case THEMES.NEWTON:
      return (
        <>
          {renderPortfolioMetaInfo}
          <Newton
            type={type}
            portfolio={portfolio}
            onSubmitMessage={sendMessage}
            webpSupported={webpSupported}
          />
        </>
      );
    case THEMES.ADAMS:
      return (
        <>
          {renderPortfolioMetaInfo}
          <Adams
            type={type}
            portfolio={portfolio}
            onSubmitMessage={sendMessage}
            webpSupported={webpSupported}
          />
        </>
      );
    default:
      throw new Error('Unsupported theme');
  }
};

Portfolio.propTypes = {
  type: null,
  domain: '',
};

Portfolio.propTypes = {
  portfolio: PropTypes.shape({
    analyticsCode: PropTypes.string,
  }).isRequired,
  domain: PropTypes.string,
  type: PropTypes.string,
};

export default Portfolio;
