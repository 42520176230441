import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { PortfolioQueryRenderer, PortfolioDemo } from './Portfolio';
import { getQueryParamValue } from './lib/helpers';

const Routes = () => {
  let domain = window?.location?.host;
  let isDemoTheme = domain === process.env.REACT_APP_PORTFOLIO_DEMO_HOST;
  if (isDemoTheme) {
    const r = getQueryParamValue('r');
    if (r) {
      domain = r.split('/')[0]; // {domain}/index.html
      isDemoTheme = false;
    }
  }

  return (
    <Switch>
      <Route
        path="/"
        // eslint-disable-next-line max-len
        render={() => (isDemoTheme ? <PortfolioDemo /> : <PortfolioQueryRenderer domain={domain} />)}
      />
      <Route
        path="/index.html"
        // eslint-disable-next-line max-len
        render={() => (isDemoTheme ? <PortfolioDemo /> : <PortfolioQueryRenderer domain={domain} />)}
      />
    </Switch>
  );
};

export default Routes;
