import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import graphqlEnvironment from '../lib/graphqlEnvironment';

const mutation = graphql`
  mutation SendPortfolioMessageMutation (
    $input: SendPortfolioContactInput!
  ) {
    sendPortfolioContact(input: $input) {
      receiverId
    }
  }
`;

const sendPortfolioMessage = ({
  input,
  onCompleted = (() => {}),
  onError = (() => {}),
  environment = graphqlEnvironment,
}) => {
  const variables = {
    input,
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      onCompleted,
      onError,
    },
  );
};

export default sendPortfolioMessage;
