import adamsData from './adamsData';
import moodieData from './moodieData';
import newtonData from './newtonData';
import { THEMES } from '../../lib/constants/portfolio';

const getPortfolioDemoData = (themeName) => {
  switch (themeName) {
    case (THEMES.ADAMS):
      return adamsData;
    case (THEMES.MOODIE):
      return moodieData;
    case (THEMES.NEWTON):
      return newtonData;
    default:
      return {};
  }
};

export default getPortfolioDemoData;
