/* eslint-disable max-len */

const editorialPhotos = [
  {
    node: {
      legacyId: '1011816163',
      name: 'DS338 - Lost in the wonderland',
      description: "Since I started in photography I have been inspired by photographers Tim Walker and Tim Burton. They are photographers who create sets and cinematographic/theatrical images.It's been about 2 years since I had this conceptual idea of ​​photoshoot. The title of this series is: \"Lost in the wonderland\". It is the story of a young woman discovering an imaginary and magical world. This first idea of ​​photoshoot came to my mind at the age of 25. By brainstorming, I found on the internet a small floor lamp that looked like a mushroom. It's funny to say, since it starts from a lamp, but at that moment everything lit up in my head. I saw the whole scenario that I wanted to create.During these two years when I had this idea in mind, I met creative peoples who wanted to invest as much as I in this project. So I presented my ideas to them, and then we started to put it forward. After making several drawings and sketches, I contact my team, and we start the creation of the sets and costumes. \nWe have created (handmade) several different sets, including giant mushrooms which measure between 5 feet and 12 feet high.\n\n🌟Cover for @horizontmag 🌟Title: Lost in the wonderland \n\nPhotographer DS sanchez Dariane Sanche \nModel Noémie Charest \nModel agency Montage Models \nMua Anabelle Deschamps \nDesigner CharlotteMorache Charlotte Morache Gordijn \nArtistic director Vé Duplain \nScenographer Marc-Antoine Gascon\nwww.dssanchez.com\nInstagram: ds_sanchez",
      width: 4480,
      height: 6720,
      images: [{
        size: 33,
        url: 'https://drscdn.500px.org/photo/1011816163/q%3D80_m%3D2000/v2?sig=d1c4190dfe19b6b5fab8ee4deefb0c0b1589f2637f3484549bc791a7cb660590',
      }, {
        size: 32,
        url: 'https://drscdn.500px.org/photo/1011816163/q%3D80_m%3D2000/v2?sig=d1c4190dfe19b6b5fab8ee4deefb0c0b1589f2637f3484549bc791a7cb660590',
      }],
    },
  },
  {
    node: {
      legacyId: '1012096742',
      name: 'DS340 - Lost in the Wonderland',
      description: "Since I started in photography I have been inspired by photographers Tim Walker and Tim Burton. They are photographers who create sets and cinematographic/theatrical images.It's been about 2 years since I had this conceptual idea of ​​photoshoot. The title of this series is: \"Lost in the wonderland\". It is the story of a young woman discovering an imaginary and magical world. This first idea of ​​photoshoot came to my mind at the age of 25. By brainstorming, I found on the internet a small floor lamp that looked like a mushroom. It's funny to say, since it starts from a lamp, but at that moment everything lit up in my head. I saw the whole scenario that I wanted to create.During these two years when I had this idea in mind, I met creative peoples who wanted to invest as much as I in this project. So I presented my ideas to them, and then we started to put it forward. After making several drawings and sketches, I contact my team, and we start the creation of the sets and costumes. \nWe have created (handmade) several different sets, including giant mushrooms which measure between 5 feet and 12 feet high.\n\n🌟Cover for @horizontmag 🌟Title: Lost in the wonderland \n\nPhotographer DS sanchez Dariane Sanche \nModel Noémie Charest \nModel agency Montage Models \nMua Anabelle Deschamps \nDesigner CharlotteMorache Charlotte Morache Gordijn \nArtistic director Vé Duplain \nScenographer Marc-Antoine Gascon\nwww.dssanchez.com\nInstagram: ds_sanchez",
      width: 4480,
      height: 6720,
      images: [{
        size: 33,
        url: 'https://drscdn.500px.org/photo/1012096742/q%3D80_m%3D2000/v2?sig=645bed5ed15da9c3762c445a7471a75a9f48ae2d43d7aaece07232ff50384a33',
      }, {
        size: 32,
        url: 'https://drscdn.500px.org/photo/1012096742/q%3D80_m%3D2000/v2?sig=645bed5ed15da9c3762c445a7471a75a9f48ae2d43d7aaece07232ff50384a33',
      }],
    },
  },
  {
    node: {
      legacyId: '1011866785',
      name: 'DS339 - Lost in the wonderland',
      description: "Since I started in photography I have been inspired by photographers Tim Walker and Tim Burton. They are photographers who create sets and cinematographic/theatrical images.It's been about 2 years since I had this conceptual idea of ​​photoshoot. The title of this series is: \"Lost in the wonderland\". It is the story of a young woman discovering an imaginary and magical world. This first idea of ​​photoshoot came to my mind at the age of 25. By brainstorming, I found on the internet a small floor lamp that looked like a mushroom. It's funny to say, since it starts from a lamp, but at that moment everything lit up in my head. I saw the whole scenario that I wanted to create.During these two years when I had this idea in mind, I met creative peoples who wanted to invest as much as I in this project. So I presented my ideas to them, and then we started to put it forward. After making several drawings and sketches, I contact my team, and we start the creation of the sets and costumes. \nWe have created (handmade) several different sets, including giant mushrooms which measure between 5 feet and 12 feet high.\n\n🌟Cover for @horizontmag 🌟Title: Lost in the wonderland \n\nPhotographer DS sanchez Dariane Sanche \nModel Noémie Charest \nModel agency Montage Models \nMua Anabelle Deschamps \nDesigner CharlotteMorache Charlotte Morache Gordijn \nArtistic director Vé Duplain \nScenographer Marc-Antoine Gascon\nwww.dssanchez.com\nInstagram: ds_sanchez",
      width: 6720,
      height: 4480,
      images: [{
        size: 33,
        url: 'https://drscdn.500px.org/photo/1011866785/q%3D80_m%3D2000/v2?sig=2ec79a4f139fd71c4750e71bf02ad3e3302e031a8149ba46cd321fcc1f128158',
      }, {
        size: 32,
        url: 'https://drscdn.500px.org/photo/1011866785/q%3D80_m%3D2000/v2?sig=2ec79a4f139fd71c4750e71bf02ad3e3302e031a8149ba46cd321fcc1f128158',
      }],
    },
  },
  {
    node: {
      legacyId: '1004210919',
      name: 'DS321 - MUSE',
      description: 'www.dssanchez.com\nInstagram: ds_sanchez\n\nPhotographer: Dariane Sanche\nModel: Alex-sandrine',
      width: 4480,
      height: 6720,
      images: [{
        size: 33,
        url: 'https://drscdn.500px.org/photo/1004210919/q%3D80_m%3D2000/v2?sig=30ba16a20e678be83c295de92142a8ebcab97cd0d4f14df5bfa458c6c376eb92',
      }, {
        size: 32,
        url: 'https://drscdn.500px.org/photo/1004210919/q%3D80_m%3D2000/v2?sig=30ba16a20e678be83c295de92142a8ebcab97cd0d4f14df5bfa458c6c376eb92',
      }],
    },
  },
  {
    node: {
      legacyId: '1005343820',
      name: 'DS322 - Alex Sandrine Août',
      description: 'www.dssanchez.com\nInstagram: ds_sanchez\n\nPhotographer: Dariane Sanche\nModel: Alex-sandrine\nLocation: Montreal, Quebec, Canada\n\nLighting: Broncolor Siros 800s',
      width: 4480,
      height: 5973,
      images: [{
        size: 33,
        url: 'https://drscdn.500px.org/photo/1005343820/q%3D80_m%3D2000/v2?sig=7cc8d346f37be1b3b7963bc6ac8ba35ab3f74285548a746a3ffbdb4487af0ed2',
      }, {
        size: 32,
        url: 'https://drscdn.500px.org/photo/1005343820/q%3D80_m%3D2000/v2?sig=7cc8d346f37be1b3b7963bc6ac8ba35ab3f74285548a746a3ffbdb4487af0ed2',
      }],
    },
  },
  {
    node: {
      legacyId: '1002804559',
      name: 'DS312 - Sweet Peonies',
      description: "For business: www.dssanchez.com\nIG: ds_sanchez\n\nNew Editorial for Latest magazine \n--> https://www.latestmagazine.net/post/sweet-peonies-webitorial \n~SWEET PEONIES~\nPhotographer DS sanchez Dariane Sanche \nAssistant Richard Kevin \nMUA Marika D'Auteuil \nStylist Maude Sen \nEarnings 1861 \nDress Marie Saint Pierre \nModel Claire Dawe-McCord \nModel agencies Folio Montreal Models International Management One Management \nFlowers B I G F L O W E R S M T L\nFor business: www.dssanchez.com",
      width: 4480,
      height: 5973,
      images: [{
        size: 33,
        url: 'https://drscdn.500px.org/photo/1002804559/q%3D80_m%3D2000/v2?sig=b20b620db14e87a5e2ba995e009b87386946e37ede1b3e612192fd01978c44fd',
      }, {
        size: 32,
        url: 'https://drscdn.500px.org/photo/1002804559/q%3D80_m%3D2000/v2?sig=b20b620db14e87a5e2ba995e009b87386946e37ede1b3e612192fd01978c44fd',
      }],
    },
  },
  {
    node: {
      legacyId: '1003513847',
      name: 'DS317 - Pearl & Peonie',
      description: '“ Don’t be like the rest of them, Darling ” Coco Chanel 🥰\n\nEditorial photoshoot published in @latestmagazine ~SWEET PEONIES~\n\nTeam credits: \nPhotographer @ds_sanchez \nAssistant @richywarhol \nMUA @lapetitevengeance \nStylist @maude.sen \nHat @fumilehat \nNecklace @vintage \nPearl top @corail_blanc \nSkirt @mariesaintpierre \nJeans @noisymay @lpgarconne \nShoes @badgleymischka @boudoir1861 \nModel @clairedawemccord \nModel agencies @foliomontreal @mimmodels @onemanagement @merakimodelmgmt \nFlowers @bigflowers_mtl \nGear:\nCamera @canoncanada 5DMarkIV, EF 35mm f1.4 + 50mm\n\nLighting @broncolor siros 800s\n\nFor Business: www.dssanchez.com\nInstagram: ds_sanchez',
      width: 3048,
      height: 4064,
      images: [{
        size: 33,
        url: 'https://drscdn.500px.org/photo/1003513847/q%3D80_m%3D2000/v2?sig=df44c4dbb8eaeddd165ec50aca163755b4a9e030666982152eb5bd0008891743',
      }, {
        size: 32,
        url: 'https://drscdn.500px.org/photo/1003513847/q%3D80_m%3D2000/v2?sig=df44c4dbb8eaeddd165ec50aca163755b4a9e030666982152eb5bd0008891743',
      }],
    },
  },
  {
    node: {
      legacyId: '1003964564',
      name: 'DS318 - glittering beauty',
      description: ' Published in @latestmagazine \nPhotographer @ds_sanchez \nAssistant @richywarhol \nMUA @lapetitevengeance \nStylist @maude.sen \nModel @clairedawemccord \nModel agencies @foliomontreal @onemanagement @merakimodelmgmt @mimmodels \nFlowers @bigflowers_mtl \nClothes:\nHat @fumilehat \nEarrings @boutique1861 \nGear:\nCamera @canoncanada 5D MarkIV + canon 100mm macro lens f2.8\n\nLighting @broncolor siros 800s\nEditing with @wacom intuos pro M.',
      width: 4465,
      height: 5954,
      images: [{
        size: 33,
        url: 'https://drscdn.500px.org/photo/1003964564/q%3D80_m%3D2000/v2?sig=51051be9123bfecedf036efe4b0af10f50b1b49bccae4dc12b21e61bd7cdc260',
      }, {
        size: 32,
        url: 'https://drscdn.500px.org/photo/1003964564/q%3D80_m%3D2000/v2?sig=51051be9123bfecedf036efe4b0af10f50b1b49bccae4dc12b21e61bd7cdc260',
      }],
    },
  },
];

const beautyPhotos = [
  {
    node: {
      legacyId: '1018380658',
      name: 'DS-348 Asian beauty',
      description: "website: www.dssanchez.com\n\nBeauty photoshoot with a beautiful asian model\nModel: Jade\nModel agency: Folio Montreal\nMakeup artist: Marika D'Auteuil\nPhotographer: DS Sanchez\n",
      width: 2016,
      height: 3024,
      images: [{
        size: 33,
        url: 'https://drscdn.500px.org/photo/1018380658/q%3D80_m%3D2000/v2?sig=1ccc5ab647950f832f5f6abe4fd32d7a262051c50167b688eaa314fdfe0e94db',
      }, {
        size: 32,
        url: 'https://drscdn.500px.org/photo/1018380658/q%3D80_m%3D2000/v2?sig=1ccc5ab647950f832f5f6abe4fd32d7a262051c50167b688eaa314fdfe0e94db',
      }],
    },
  },
  {
    node: {
      legacyId: '1018415007',
      name: 'DS-349 - Jade',
      description: "www.dssanchez.com\nPhotographer: Dariane Sanche (ds_sanchez)\nModel: Jade\nModel agency: Folio Montreal\nMUA: Marika D'Auteuil",
      width: 1792,
      height: 2688,
      images: [{
        size: 33,
        url: 'https://drscdn.500px.org/photo/1018415007/q%3D80_m%3D2000/v2?sig=a274ef41db5a7b82ea0926f292d8cdcd81d670a96b4890fce591c2ce1284e2b4',
      }, {
        size: 32,
        url: 'https://drscdn.500px.org/photo/1018415007/q%3D80_m%3D2000/v2?sig=a274ef41db5a7b82ea0926f292d8cdcd81d670a96b4890fce591c2ce1284e2b4',
      }],
    },
  },
  {
    node: {
      legacyId: '1009916606',
      name: 'DS333 - Makeup my mood',
      description: 'Photographer: Dariane Sanche\nInstagram: ds_sanchez\nwebsite: www.dssanchez.com\n\nMakeup artist: Anabelle Deschamps\n\nModel: Marilou\nModel agency: Folio Montreal\n\nStudio Location: Montreal, Quebec, Canada',
      width: 3744,
      height: 5616,
      images: [{
        size: 33,
        url: 'https://drscdn.500px.org/photo/1009916606/q%3D80_m%3D2000/v2?sig=2b6e52168e5b67279617d33ec583dc4278632d09eb80580def61b654bda0cd32',
      }, {
        size: 32,
        url: 'https://drscdn.500px.org/photo/1009916606/q%3D80_m%3D2000/v2?sig=2b6e52168e5b67279617d33ec583dc4278632d09eb80580def61b654bda0cd32',
      }],
    },
  },
  {
    node: {
      legacyId: '1002135297',
      name: 'DS309 - JUSTINE',
      description: "www.dssanchez.com\nInstagram: ds_sanchez\n\nModel: Justine Delsaut\nModel agency: Folio Montreal\nMUA: Marika D'Auteuil (IG @lapetitevengeance)\nPhotographer: Dariane Sanche\n\nGear:\nCamera: Canon 5D MarkIV\nLens: Canon EF 100mm macro f2.8\nLighting: \nBroncolor headflash Siros 800s\n1 octabox 150cm + 6x6feet diffuser 1stop, over the model head\n1 octabox 75cm + 40 degrees grid in front of model face",
      width: 4480,
      height: 6448,
      images: [{
        size: 33,
        url: 'https://drscdn.500px.org/photo/1002135297/q%3D80_h%3D600/v2?sig=378406ea5dc1b41cccdcf1d7e41c481cdc411f01897bc3f4ce8c1c6666c0a313',
      }, {
        size: 32,
        url: 'https://drscdn.500px.org/photo/1002135297/q%3D80_h%3D300/v2?sig=e52e0e3b89b409522ba829be69635bd0f74bf0ad6aec64152acf8dbe6dfcdd80',
      }],
    },
  },
  {
    node: {
      legacyId: '1002298835',
      name: 'DS310 - Beauty & Lace',
      description: "www.dssanchez.com\nInstagram: ds_sanchez\n\nPhotographer: Dariane Sanche @ds_sanchez\nModel: Justine Delsaut\nModel agency: Folio montreal\nMUA: Marika D'Auteuil (IG: lapetitevengeance)\n\nLighting:\n2 Broncolor head flash silos 800s:\n1x over model head+ 150cm octabox + 1 stop diffuser 6x6 foot\n1x in front of model face +  75cm octabox + 40 degree grid",
      width: 4480,
      height: 6720,
      images: [{
        size: 33,
        url: 'https://drscdn.500px.org/photo/1002298835/q%3D80_m%3D2000/v2?sig=eeb7af26b3137deccc067b04b1a4a7e127801ac777c27f56652c9143527f468c',
      }, {
        size: 32,
        url: 'https://drscdn.500px.org/photo/1002298835/q%3D80_m%3D2000/v2?sig=eeb7af26b3137deccc067b04b1a4a7e127801ac777c27f56652c9143527f468c',
      }],
    },
  },
  {
    node: {
      legacyId: '301916837',
      name: 'DS258 - MARILOU',
      description: 'www.dssanchez.com\nInstagram: ds_sanchez\nFacebook: DS Sanchez\n\nPhotographer: Dariane Sanche @dssanchez\nMUA: Anabelle Deschamps @missanabelle\nModel: Marilou Bélanger\nModel agency: Folio Montreal, Wilhelmina London',
      width: 3744,
      height: 4992,
      images: [{
        size: 33,
        url: 'https://drscdn.500px.org/photo/301916837/q%3D80_m%3D2000/v2?sig=a544c5cd287978051f2796835a2072e342160b1bb275a587f3256134af373fce',
      }, {
        size: 32,
        url: 'https://drscdn.500px.org/photo/301916837/q%3D80_m%3D2000/v2?sig=a544c5cd287978051f2796835a2072e342160b1bb275a587f3256134af373fce',
      }],
    },
  },
  {
    node: {
      legacyId: '232117281',
      name: 'DS172 - JULIA & butterfly',
      description: "www.dssanchez.com\n\nModel: Julia @scoop agency Montreal\nPhotographer: Dariane sanche @dssanchez\nMUA: Marika D'Auteuil @Lapetitevengeance",
      width: 1535,
      height: 2048,
      images: [{
        size: 33,
        url: 'https://drscdn.500px.org/photo/232117281/q%3D80_m%3D2000/v2?sig=fc1c5112a24be2562f52c13f350870ade674ab447a8bafb682c5401d16fc49b5',
      }, {
        size: 32,
        url: 'https://drscdn.500px.org/photo/232117281/q%3D80_m%3D2000/v2?sig=fc1c5112a24be2562f52c13f350870ade674ab447a8bafb682c5401d16fc49b5',
      }],
    },
  },
];

const celebrityPhotos = [
  {
    node: {
      legacyId: '293397009',
      name: 'DS244 - Air Force',
      description: 'www.dssanchez.com\nInstagram: ds_sanchez\nFacebook: DS Sanchez\n\nModel: Samuel Ostrout\nModel Agency: Folio model Agency Montreal\nPhotographer: Dariane Sanche @ds_sanchez\nH&amp;M: Dariane Sanche\nStudio: DS Sanchez, Montreal, Quebec, Canada',
      width: 5616,
      height: 3744,
      images: [{
        size: 33,
        url: 'https://drscdn.500px.org/photo/293397009/q%3D80_m%3D2000/v2?sig=1e3ffd85cb03bdc5a88caca42ac959b361a76b12f48812d61197c35c881db061',
      }, {
        size: 32,
        url: 'https://drscdn.500px.org/photo/293397009/q%3D80_m%3D2000/v2?sig=1e3ffd85cb03bdc5a88caca42ac959b361a76b12f48812d61197c35c881db061',
      }],
    },
  },
  {
    node: {
      legacyId: '271349151',
      name: 'DS215 - Cynthia Dulude',
      description: 'www.dssanchez.com\nInstagram: https://www.instagram.com/ds_sanchez/ \n\nPhotographer: Dariane Sanche\nModel: Cynthia Dulude\nStylist: Anne-Marie Côté\nAssistant photo: Marc-Antoine Gascon\nLocation: Pointe du Moulin, Ile Perrot, Quebec, Canada',
      width: 3744,
      height: 5556,
      images: [{
        size: 33,
        url: 'https://drscdn.500px.org/photo/271349151/q%3D80_m%3D2000/v2?sig=26040b1d6806c329a3167f526ad7480e288816d5c1f156dac4b3dcd0af2c84bf',
      }, {
        size: 32,
        url: 'https://drscdn.500px.org/photo/271349151/q%3D80_m%3D2000/v2?sig=26040b1d6806c329a3167f526ad7480e288816d5c1f156dac4b3dcd0af2c84bf',
      }],
    },
  },
  {
    node: {
      legacyId: '1006159772',
      name: 'DS324 - Gabriella Papadakis & Madison Hubbell',
      description: 'Photographer: Dariane Sanche\nInstagram: ds_sanchez\n\nLeft model: Madison Hubbell\nRight model: Gabriella Papadakis\n\nStudio: Montreal, Quebec, Canada',
      width: 4352,
      height: 6196,
      images: [{
        size: 33,
        url: 'https://drscdn.500px.org/photo/1006159772/q%3D80_m%3D2000/v2?sig=d00890a5e02a2f8934a091778baa7d395d3b89bbb9e1c5311b436d3724d7c0cf',
      }, {
        size: 32,
        url: 'https://drscdn.500px.org/photo/1006159772/q%3D80_m%3D2000/v2?sig=d00890a5e02a2f8934a091778baa7d395d3b89bbb9e1c5311b436d3724d7c0cf',
      }],
    },
  },
  {
    node: {
      legacyId: '1005733507',
      name: 'DS323 - Gabriella Papadakis & Madison Hubbel',
      description: 'www.dssanchez.com\nInstagram: ds_sanchez\n\nPhotographer: Dariane Sanche\nLeft model: Madison Hubbel (Olympian figure skating & Ice dancer)\nRight model: Gabriella Papadakis (Olympian figure skating & Ice dancer)\n\nStudio: DS Sanchez\nLocation: Montreal, Quebec, Canada',
      width: 3812,
      height: 4186,
      images: [{
        size: 33,
        url: 'https://drscdn.500px.org/photo/1005733507/q%3D80_m%3D2000/v2?sig=1d5d6f4321ec9e116225f03763d0e27657b40a578159a7a7263a095d0d3c488b',
      }, {
        size: 32,
        url: 'https://drscdn.500px.org/photo/1005733507/q%3D80_m%3D2000/v2?sig=1d5d6f4321ec9e116225f03763d0e27657b40a578159a7a7263a095d0d3c488b',
      }],
    },
  },
  {
    node: {
      legacyId: '1019348393',
      name: 'DS 351 - Luxury summer vibe',
      description: 'www.dssanchez.com\nInstagram: ds_sanchez\n\nPhotograper: Dariane Sanche\nModel: Jessie Nadeau\nAssistant: Marc-Antoine Gascon',
      width: 4480,
      height: 6720,
      images: [{
        size: 33,
        url: 'https://drscdn.500px.org/photo/1019348393/q%3D80_m%3D2000/v2?sig=ead6aa86a934463e478d7e3750029a5046778884437bc8e9c21d4d811c6ec63a',
      }, {
        size: 32,
        url: 'https://drscdn.500px.org/photo/1019348393/q%3D80_m%3D2000/v2?sig=ead6aa86a934463e478d7e3750029a5046778884437bc8e9c21d4d811c6ec63a',
      }],
    },
  },
  {
    node: {
      legacyId: '272852283',
      name: 'DS217 - Cynthia Dulude',
      description: 'www.dssanchez.com\ninstagram: https://www.instagram.com/ds_sanchez/ \n\nmodel: Cynthia Dulude\nPhotographer: Dariane Sanche @DS Sanchez\nStylist: Anne-Marie Côté\nPhoto assistant: Marc-Antoine Gascon\nLocation: Pointe-du-moulin, Ile Perrot, Quebec, Canada',
      width: 3365,
      height: 3878,
      images: [{
        size: 33,
        url: 'https://drscdn.500px.org/photo/272852283/q%3D80_m%3D2000/v2?sig=3766b3f55c3423e832a7be5940e8d0c0cd0a9226eb339119c397b7936ad9e649',
      }, {
        size: 32,
        url: 'https://drscdn.500px.org/photo/272852283/q%3D80_m%3D2000/v2?sig=3766b3f55c3423e832a7be5940e8d0c0cd0a9226eb339119c397b7936ad9e649',
      }],
    },
  },
  {
    node: {
      legacyId: '239644643',
      name: 'DS180 - Cynthia Dulude',
      description: 'www.dssanchez.com\n\nYoutube chanel of Cynthia Dulude: https://www.youtube.com/user/cynthiadulude \n\nModel: @Cynthia Dulude \nMakeup: @Cynthia Dulude\nPhotographe Dariane Sanche @dssanchez',
      width: 3447,
      height: 4845,
      images: [{
        size: 33,
        url: 'https://drscdn.500px.org/photo/239644643/q%3D80_m%3D2000/v2?sig=816dfeae51651a0b928125b2f69644a282f9ea1ece370782f0ed3b89313d7903',
      }, {
        size: 32,
        url: 'https://drscdn.500px.org/photo/239644643/q%3D80_m%3D2000/v2?sig=816dfeae51651a0b928125b2f69644a282f9ea1ece370782f0ed3b89313d7903',
      }],
    },
  },
];

const commercialPhotos = [
  {
    node: {
      legacyId: '302506009',
      name: 'DS260 - TWINS',
      description: 'www.dssanchez.com\nInstagram: ds_sanchez\nFacebook: DS Sanchez\n\nTwins models: Laurie &amp; Josée Pépin\nPhotographer: Dariane Sanche @ds_sanchez',
      width: 2048,
      height: 1365,
      images: [{
        size: 33,
        url: 'https://drscdn.500px.org/photo/302506009/q%3D80_m%3D2000/v2?sig=d2346f29dc1207b74887d5f3ae14028012e972810b771d9268765d04a611653d',
      }, {
        size: 32,
        url: 'https://drscdn.500px.org/photo/302506009/q%3D80_m%3D2000/v2?sig=d2346f29dc1207b74887d5f3ae14028012e972810b771d9268765d04a611653d',
      }],
    },
  },
  {
    node: {
      legacyId: '1000750448',
      name: 'DS301 - STEPHANIE',
      description: 'for business: www.dssanchez.com\nInstagram: ds_sanchez\n\nModel: Stephanie Beaulieu\nModel agency: EMA models\nStylist: Anne-Marie Coté\nPhotographer: Dariane Sanche',
      width: 3750,
      height: 4999,
      images: [{
        size: 33,
        url: 'https://drscdn.500px.org/photo/1000750448/q%3D80_m%3D2000/v2?sig=92f2a6392ca3a4b3bbd34aaffebab41545a5d661d13688401ed8ffdffcf46b24',
      }, {
        size: 32,
        url: 'https://drscdn.500px.org/photo/1000750448/q%3D80_m%3D2000/v2?sig=92f2a6392ca3a4b3bbd34aaffebab41545a5d661d13688401ed8ffdffcf46b24',
      }],
    },
  },
  {
    node: {
      legacyId: '299158307',
      name: 'DS253 - NEW ERA',
      description: 'www.dssanchez.com\nInstagram: ds_sanchez\nFacebook: DS Sanchez\n\nPhotographer: Dariane Sanche @ds_sanchez\nModel: Stephanie @ema models\n\nStudio: ds sanchez, Montreal, Quebec',
      width: 3818,
      height: 5091,
      images: [{
        size: 33,
        url: 'https://drscdn.500px.org/photo/299158307/q%3D80_m%3D2000/v2?sig=b57efb3e405687681a2dc1dd771f7531905ba9525bb70cf2e05e876cb124dc29',
      }, {
        size: 32,
        url: 'https://drscdn.500px.org/photo/299158307/q%3D80_m%3D2000/v2?sig=b57efb3e405687681a2dc1dd771f7531905ba9525bb70cf2e05e876cb124dc29',
      }],
    },
  },
  {
    node: {
      legacyId: '291167293',
      name: 'DS236 - Elise fall season',
      description: 'www.dssanchez.com\nInstagram: ds_sanchez\nFacebook: DS_Sanchez\n\nPhotographer: Dariane Sanche @DS sanchez\nModel: Elise Denys\nH&amp;M: Christophe Ortega\nLocation: Paris, France',
      width: 3744,
      height: 5616,
      images: [{
        size: 33,
        url: 'https://drscdn.500px.org/photo/291167293/q%3D80_m%3D2000/v2?sig=80b6692101ebb6ea13311dbea2c9d538fd5874bccd9b7461792e3f9793a7457f',
      }, {
        size: 32,
        url: 'https://drscdn.500px.org/photo/291167293/q%3D80_m%3D2000/v2?sig=80b6692101ebb6ea13311dbea2c9d538fd5874bccd9b7461792e3f9793a7457f',
      }],
    },
  },
  {
    node: {
      legacyId: '287428995',
      name: 'Untitled',
      description: '',
      width: 3744,
      height: 5406,
      images: [{
        size: 33,
        url: 'https://drscdn.500px.org/photo/287428995/q%3D80_m%3D2000/v2?sig=3c8951d6ba2d7b48c9770211f5fd82da40021976de9fce6d64bf8b1a22bc8ff1',
      }, {
        size: 32,
        url: 'https://drscdn.500px.org/photo/287428995/q%3D80_m%3D2000/v2?sig=3c8951d6ba2d7b48c9770211f5fd82da40021976de9fce6d64bf8b1a22bc8ff1',
      }],
    },
  },
  {
    node: {
      legacyId: '287428993',
      name: 'Untitled',
      description: '',
      width: 3744,
      height: 4992,
      images: [{
        size: 33,
        url: 'https://drscdn.500px.org/photo/287428993/q%3D80_m%3D2000/v2?sig=9d28e3fd3d1d5f3eb1a6b74000fe286245f3fcde14f46f3795b8b7a7181bbaae',
      }, {
        size: 32,
        url: 'https://drscdn.500px.org/photo/287428993/q%3D80_m%3D2000/v2?sig=9d28e3fd3d1d5f3eb1a6b74000fe286245f3fcde14f46f3795b8b7a7181bbaae',
      }],
    },
  },
  {
    node: {
      legacyId: '229960637',
      name: 'DS166 - MALIE',
      description: 'www.dssanchez.com\n\nModel: Malie @anotherspecies agency\nPhotographer: Dariane Sanche @DSsanchez',
      width: 3744,
      height: 5616,
      images: [{
        size: 33,
        url: 'https://drscdn.500px.org/photo/229960637/q%3D80_m%3D2000/v2?sig=00284c26cfc9af0bae2e9280b9c6a45942dac568b770082acd68b239040795ae',
      }, {
        size: 32,
        url: 'https://drscdn.500px.org/photo/229960637/q%3D80_m%3D2000/v2?sig=00284c26cfc9af0bae2e9280b9c6a45942dac568b770082acd68b239040795ae',
      }],
    },
  },
  {
    node: {
      legacyId: '229855607',
      name: 'DS165 - "MALIE"',
      description: 'www.dssanchez.com\n\nModel: Malie @anotherspecies agency\nPhotographer: Dariane Sanche @DSsanchez',
      width: 3744,
      height: 4732,
      images: [{
        size: 33,
        url: 'https://drscdn.500px.org/photo/229855607/q%3D80_m%3D2000/v2?sig=939ccb8bdce8f934129fd2bafd32abff8aba4262a60e08c596e180a4a2cfe9ea',
      }, {
        size: 32,
        url: 'https://drscdn.500px.org/photo/229855607/q%3D80_m%3D2000/v2?sig=939ccb8bdce8f934129fd2bafd32abff8aba4262a60e08c596e180a4a2cfe9ea',
      }],
    },
  },
];

const folders = [
  {
    id: '2',
    name: 'Editorial',
    coverPhoto: {
      legacyId: '1011816163',
      name: 'DS338 - Lost in the wonderland',
      description: "Since I started in photography I have been inspired by photographers Tim Walker and Tim Burton. They are photographers who create sets and cinematographic/theatrical images.It's been about 2 years since I had this conceptual idea of ​​photoshoot. The title of this series is: \"Lost in the wonderland\". It is the story of a young woman discovering an imaginary and magical world. This first idea of ​​photoshoot came to my mind at the age of 25. By brainstorming, I found on the internet a small floor lamp that looked like a mushroom. It's funny to say, since it starts from a lamp, but at that moment everything lit up in my head. I saw the whole scenario that I wanted to create.During these two years when I had this idea in mind, I met creative peoples who wanted to invest as much as I in this project. So I presented my ideas to them, and then we started to put it forward. After making several drawings and sketches, I contact my team, and we start the creation of the sets and costumes. \nWe have created (handmade) several different sets, including giant mushrooms which measure between 5 feet and 12 feet high.\n\n🌟Cover for @horizontmag 🌟Title: Lost in the wonderland \n\nPhotographer DS sanchez Dariane Sanche \nModel Noémie Charest \nModel agency Montage Models \nMua Anabelle Deschamps \nDesigner CharlotteMorache Charlotte Morache Gordijn \nArtistic director Vé Duplain \nScenographer Marc-Antoine Gascon\nwww.dssanchez.com\nInstagram: ds_sanchez",
      width: 4480,
      height: 6720,
      images: [{
        size: 33,
        url: 'https://drscdn.500px.org/photo/1011816163/q%3D80_h%3D600/v2?sig=e1cd55bd78660dc2da729c48172ef1cc551ad0633e0e74661803c46280830be0',
      }, {
        size: 32,
        url: 'https://drscdn.500px.org/photo/1011816163/q%3D80_h%3D300/v2?sig=929fde6329d8b15ff32590196f86cab768e7c7fe492c44cc836f3a1f24e80ba8',
      }],
    },
    photos: {
      edges: [...editorialPhotos],
    },
    description: 'Inspired by some of the great fashion photographers, I take a cinematographic and theatrical approach when conceptualizing and shooting my fashion editorial images.',
  },
  {
    id: '3',
    name: 'Beauty',
    coverPhoto: {
      legacyId: '1018380658',
      name: 'DS-348 Asian beauty',
      description: "website: www.dssanchez.com\n\nBeauty photoshoot with a beautiful asian model\nModel: Jade\nModel agency: Folio Montreal\nMakeup artist: Marika D'Auteuil\nPhotographer: DS Sanchez\n",
      width: 2016,
      height: 3024,
      images: [{
        size: 33,
        url: 'https://drscdn.500px.org/photo/1018380658/q%3D80_h%3D600/v2?sig=770383cfb6d75edd7e834ed65a9eb1daf1d6c569850444d0ef4e43849c3706b1',
      }, {
        size: 32,
        url: 'https://drscdn.500px.org/photo/1018380658/q%3D80_h%3D300/v2?sig=91449f4c5ec4e93abfa009581516bae0c0068486bd35207ce700281a5b7accf9',
      }],
    },
    photos: {
      edges: [...beautyPhotos],
    },
    description: 'My beauty photography combines haute couture, textures, nature, and classical styles to create modern yet timeless images.',
  },
  {
    id: '4',
    name: 'Celebrity',
    coverPhoto: {
      legacyId: '293397009',
      name: 'DS244 - Air Force',
      description: 'www.dssanchez.com\nInstagram: ds_sanchez\nFacebook: DS Sanchez\n\nModel: Samuel Ostrout\nModel Agency: Folio model Agency Montreal\nPhotographer: Dariane Sanche @ds_sanchez\nH&amp;M: Dariane Sanche\nStudio: DS Sanchez, Montreal, Quebec, Canada',
      width: 5616,
      height: 3744,
      images: [{
        size: 33,
        url: 'https://drscdn.500px.org/photo/293397009/q%3D80_h%3D600/v2?sig=19f896c4c92ed8c59f889fc4645eb2f0690eb1fc4d4d8cbab3b33a31097922d0',
      }, {
        size: 32,
        url: 'https://drscdn.500px.org/photo/293397009/q%3D80_h%3D300/v2?sig=9918baaeda3f744ffceb1c4a55fdc882be9f7988049a85a0144a325d28bad234',
      }],
    },
    photos: {
      edges: [...celebrityPhotos],
    },
    description: 'I incorporate fashion and beauty techniques into my celebrity photoshoots to create a harmonious photo. I like to focus on the eyes and capture little details of my models to give each photo a soul.',
  },
  {
    id: '5',
    name: 'Commercial',
    coverPhoto: {
      legacyId: '302506009',
      name: 'DS260 - TWINS',
      description: 'www.dssanchez.com\nInstagram: ds_sanchez\nFacebook: DS Sanchez\n\nTwins models: Laurie &amp; Josée Pépin\nPhotographer: Dariane Sanche @ds_sanchez',
      width: 2048,
      height: 1365,
      images: [{
        size: 33,
        url: 'https://drscdn.500px.org/photo/302506009/q%3D80_h%3D600/v2?sig=68d748e09e5beaa1e5ffa62ca72414ca033db60e8f147acb75a1df4799d0d33a',
      }, {
        size: 32,
        url: 'https://drscdn.500px.org/photo/302506009/q%3D80_h%3D300/v2?sig=ea7e663718e702954503c2fd42c3e2268b7c39b6fa616c241242de4b7af25194',
      }],
    },
    photos: {
      edges: [...commercialPhotos],
    },
    description: 'This folder consists of work I have taken for clients. In my commercial photography I use my signature style of blending fashion and editorial to give my clients unique on-brand images.',
  },
];

const portfolio = {
  theme: {
    name: 'NEWTON',
  },
  title: 'Dariane Sanche',
  status: 'ACTIVE',
  description: 'Dariane Sanche is a professional photographer specializing in fashion and beauty. Also known by her artist name "DS Sanchez", Dariane has 10 years of experience in photography and a little more in photo editing. She made her photography debut at the age of 17.\n\nWell known in Montreal\'s fashion industry, Dariane stands out for her unique and theatrical artistic style that is now her trademark. In 2018, she was nominated by the Montreal Museum of Fine Arts as one of the 100 most influential women in the fashion industry in Montreal.',
  folders,
  appearance: 'LIGHT',
  colour: 'ACCENT_TEAL',
  font: 'PT_SERIF',
  coverPhoto: {
    avatar: {
      images: [
        {
          url: 'https://wsk-mweb.oss-cn-hangzhou.aliyuncs.com/ipic/2020-12-10-img%20-1-.jpg',
        },
      ],
    },
  },
  user: {
    socialMedia: {
      website: null,
      instagram: 'ds_sanchez',
      facebook: 'dssanchezphotographer',
      twitter: null,
    },
    username: 'darianesanche',
    userProfile: {
      country: 'UK',
      city: 'London',
    },
  },
  visibilityOptions: {
    location: 'HIDDEN',
    pxProfile: 'VISIBLE',
    instagram: 'VISIBLE',
    facebook: 'VISIBLE',
    twitter: 'HIDDEN',
  },
};

export default portfolio;
