import { FONTS, MODES } from '../constants/portfolio';

const color = (mode) => {
  const isDarkMode = mode === MODES.DARK;
  return {
    white: '#FFFFFF',
    foreground1: isDarkMode ? '#FFFFFF' : '#1C1C1C',
    foreground2: isDarkMode ? '#979797' : '#707070',
    background1: isDarkMode ? '#1C1C1C' : '#FFFFFF',
    background2: isDarkMode ? '#303030' : '#F4F4F4',
    border: isDarkMode ? '#575757' : '#D7D7D7',
    accentOrange: isDarkMode ? '#C76C01' : '#B46101',
    accentGold: isDarkMode ? '#C78A46' : '#9E6B33',
    accentTeal: isDarkMode ? '#3DADA5' : '#2C7B76',
    accentBlue: isDarkMode ? '#5D91FF' : '#1962FF',
    accentViolet: isDarkMode ? '#8189FF' : '#5D67ED',
    accentAsh: isDarkMode ? '#AA9B93' : '#81746E',
    accentMono: isDarkMode ? '#A7A7A7' : '#383838',
  };
};

const common = {
  // Responsive breakpoints
  responsiveSmall: 415,
  responsiveSmallXl: 475,
  responsiveMedium: 768,
  responsiveTopnavMobile: 855,
  responsiveLarge: 1024,
  responsiveLargeXl: 1280,
  responsiveDesktop: 1440,
  responsiveDesktopXl: 1920,

  // Font Sizes
  fontSizeHeadlineLarge: '48px',
  fontSizeHeadline: '36px',
  fontSizeHeadingLarge: '28px',
  fontSizeHeading: '24px',
  fontSizeBodyLarge: '18px',
  fontSizeBody: '16px',
  fontSizeBodySmall: '14px',
  fontSizeCaption: '12px',
  logoLarge: '28px',
  logo: '24px',
  buttonLarge: '16px',
  button: '14px',

  lineHeightHeadlineLarge: '52px',
  lineHeightHeadline: '40px',
  lineHeightHeadingLarge: '32px',
  lineHeightHeading: '28px',
  lineHeightBodyLarge: '24px',
  lineHeightBody: '24px',
  lineHeightBodySmall: '20px',
  lineHeightCaption: '16px',
  lineHeightLogoLarge: '32px',
  lineHeightLogo: '28px',
  lineHeightButtonLarge: '24px',
  lineHeightButton: '24px',

  letterSpacingButtonLarge: '0.8',
  letterSpacingButton: '0.8',
};

const setFontFamily = (fontFamily) => {
  switch (fontFamily) {
    case FONTS.INTER:
      return { fontFamily: 'Inter, sansserif' };
    case FONTS.ALEO:
      return { fontFamily: 'Aleo, serif' };
    case FONTS.PT_SERIF:
      return { fontFamily: "'PT Serif', serif" };
    default:
      throw new Error('fontFamily is required for setting theme');
  }
};

const theme = (fontFamily, mode) => ({
  ...color(mode),
  ...common,
  ...setFontFamily(fontFamily),
  portfolioColor: color(mode),
  portfolioFontFamily: setFontFamily(fontFamily),
});

export default theme;
