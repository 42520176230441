// Compatible with IE 11
// eslint-disable-next-line
export const getQueryParamValue = (name) => {
  const queryParam = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]'); // eslint-disable-line
  const regex = new RegExp('[\\?&]' + queryParam + '=([^&#]*)'); // eslint-disable-line
  const results = regex.exec(window.location.search);
  return results ? decodeURIComponent(results[1].replace(/\+/g, ' ')) : null;
};

// check support of webp format
export const checkWebpSupport = (callback) => {
  const testImages = {
    lossy: 'UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA',
  };
  const img = new Image();
  img.onload = () => {
    const result = (img.width > 0) && (img.height > 0);
    callback(result);
  };
  img.onerror = () => callback(false);
  img.src = `data:image/webp;base64,${testImages.lossy}`;
};
