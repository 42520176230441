import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { ThemeProvider } from 'styled-components';

import NotFound from 'web-shared-components/components/PortfolioTemplates/common/NotFound';
import PortfolioMetaInfo from 'web-shared-components/components/MetaInfo/PortfolioMetaInfo';

import BlackIcon from '../assets/black-icon.png';
import { GlobalStyle, theme } from '../lib/theme';
import { getQueryParamValue } from '../lib/helpers';
import Portfolio from './Portfolio';
import getPortfolioDemoData from './data';
import { THEMES, MODES } from '../lib/constants/portfolio';

const PortfolioDemo = () => {
  const [appearance, setAppearance] = useState(null);
  const [demoTheme, setDemoTheme] = useState(null);
  // eslint-disable-next-line max-len
  // const r = getQueryParamValue('r');
  // const description = `r is ${r}, href is ${window.location.href}`;

  useEffect(() => {
    const handleMessage = (event) => {
      const domain = process.env.REACT_APP_BASE_URL.slice(8);
      const webUrl = `https://web.${domain}`;
      const allowedDomains = [webUrl, process.env.REACT_APP_BASE_URL];
      const allowedAppearance = Object.values(MODES);

      if (!allowedDomains.includes(event?.origin)) return null;
      if (Object.keys(event?.data)?.length > 1) return null;
      if (!allowedAppearance.includes(event?.data?.appearance)) return null;
      return setAppearance(event?.data?.appearance);
    };

    window.addEventListener('message', handleMessage, false);

    return () => window.removeEventListener('message', handleMessage, false);
  }, []);

  const qsAppearance = getQueryParamValue('appearance')?.toUpperCase();
  const qsTheme = getQueryParamValue('theme')?.toUpperCase();
  useMemo(() => {
    if (qsAppearance) setAppearance(qsAppearance);
  }, [qsAppearance]);

  useMemo(() => {
    if (qsTheme) setDemoTheme(qsTheme);
  }, [qsTheme]);

  const portfolioTheme = useCallback(theme('INTER', appearance), [appearance]);

  const isValidAppearance = Object.values(MODES).includes(appearance);
  const isValidTheme = Object.values(THEMES).includes(demoTheme);
  const isInvalidQsParams = !isValidAppearance || !isValidTheme;

  // Return NotFound if not in an iframe or has invalid query param values
  if (window.self === window.top || isInvalidQsParams) {
    return (
      <>
        <PortfolioMetaInfo
          title="500px Portfolio / Not Found"
          description=""
          icon={BlackIcon}
        />
        <NotFound />
      </>
    );
  }

  return (
    <ThemeProvider theme={portfolioTheme}>
      <GlobalStyle />
      <Portfolio
        portfolio={{
          ...getPortfolioDemoData(demoTheme),
          appearance: appearance || qsAppearance,
        }}
        type="theme"
      />
    </ThemeProvider>
  );
};

export default PortfolioDemo;
