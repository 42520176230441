/* eslint-disable max-len */
import { createGlobalStyle } from 'styled-components';

import * as Aleo from './fonts/aleo';
import * as Inter from './fonts/inter';
import * as PtSerif from './fonts/ptSerif';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Aleo';
    font-style: normal;
    font-weight: 400;
    src: url(${Aleo.Eot});
    src: local('Aloe Regular'), local('Aleo-Regular'),
      url(${Aleo.Eot}) format('embedded-opentype'),
      url(${Aleo.Woff2}) format('woff2'),
      url(${Aleo.Woff}) format('woff'),
      url(${Aleo.Tff}) format('truetype'),
      url(${Aleo.Svg}) format('svg');
  }

  @font-face {
  font-family: 'PT Serif';
  font-style: normal;
  font-weight: 400;
  src: url(${PtSerif.Eot});
  src: local('PT Serif'), local('PTSerif-Regular'),
      url(${PtSerif.Eot}) format('embedded-opentype'),
      url(${PtSerif.Woff2}) format('woff2'),
      url(${PtSerif.Woff}) format('woff'),
      url(${PtSerif.Tff}) format('truetype'),
      url(${PtSerif.Svg}) format('svg');
  }

  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    src: url(${Inter.Eot});
    src: local('Inter'),
      url(${Inter.Eot}) format('embedded-opentype'),
      url(${Inter.Woff2}) format('woff2'),
      url(${Inter.Woff}) format('woff'),
      url(${Inter.Tff}) format('truetype'),
      url(${Inter.Svg}) format('svg');
  }

  body {
    font-family: ${props => props.theme.fontFamily};
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    @include default-font-family;
    text-rendering: optimizeLegibility;
  }
`;

export default GlobalStyle;
