import { shouldPolyfill } from '@formatjs/intl-getcanonicallocales/should-polyfill';
import localeMessages from '../locales/messages.json';

async function polyfill() {
  // This platform already supports Intl.getCanonicalLocales
  if (shouldPolyfill()) {
    await import('@formatjs/intl-getcanonicallocales/polyfill');
  }
}

// List of languages for which we provide translations
const supportedLangs = ['de', 'en', 'es', 'fr', 'it', 'ja', 'ko', 'pt-BR', 'ru', 'tr', 'zh-CN'];

// Figure out the browser language setting
const browserLanguage = (navigator.languages && navigator.languages[0])
  || navigator.language
  || navigator.browserLanguage;

// Ensure that we support the browser language, default to en otherwise
const language = supportedLangs.indexOf(browserLanguage) >= 0 ? browserLanguage : 'en';

// Strip off the region code
const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];

export function setupIntl() {
  // react-intl relies on the browser supporting Intl, which may not be the case in some older
  // (but not that old) version of Safari, in particular. If it's not present, dynamically
  // import the polyfill
  return polyfill();
}

export function getLocale(userLocale) {
  const locale = userLocale || language;

  // Grab the translated strings (messages) for the current language, and default any missing
  // messages to english
  // Fallback priority:
  //   userLocale (settings) > language (browser) > language without region code > english

  // Note: As the set of strings gets larger, we'll likely want to split the messages into separate
  // modules and load them dynamically
  const messages = Object.assign(
    localeMessages.en,
    localeMessages[locale] || localeMessages[languageWithoutRegionCode],
  );

  return { locale, messages };
}
