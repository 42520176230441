import {
  Environment,
  Network,
  RecordSource,
  Store,
} from 'relay-runtime';

import {
  getQueryParamValue,
} from './helpers';

const API_URL = process.env.REACT_APP_API_URL_LOCAL || process.env.REACT_APP_API_URL || '';

// Borrowed from: https://relay.dev/docs/en/network-layer
// Define a function that fetches the results of an operation (query/mutation/etc)
// and returns its results as a Promise:
function fetchQuery(
  operation,
  variables,
  // cacheConfig,
  // uploadables,
) {
  const headers = {
    'content-type': 'application/json',
  };

  const previewToken = getQueryParamValue('previewToken');
  if (previewToken) {
    headers['x-500px-portfolio-token'] = previewToken;
  }

  return fetch(`${API_URL}/graphql`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      query: operation.text, // GraphQL text from input
      variables,
    }),
  }).then(response => response.json());
}

// Create a network layer from the fetch function
const network = Network.create(fetchQuery);
const store = new Store(new RecordSource());

const environment = new Environment({
  network,
  store,
});

export default environment;
