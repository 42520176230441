/* eslint-disable max-len */

const travelPhotos = [
  {
    node: {
      legacyId: '1000283936',
      width: 1000,
      height: 1500,
      images: [
        {
          size: 33,
          url: 'https://drscdn.500px.org/photo/1000283936/q%3D80_m%3D2000/v2?sig=bb8adcbdecdcf109d1baea48d45dd73db77bc84ceeb5e83f7f858d8bce7d170a',
        },
        {
          size: 32,
          url: 'https://drscdn.500px.org/photo/1000283936/q%3D80_m%3D2000/v2?sig=bb8adcbdecdcf109d1baea48d45dd73db77bc84ceeb5e83f7f858d8bce7d170a',
        },
      ],
    },
  },
  {
    node: {
      legacyId: '1000514214',
      width: 1001,
      height: 1500,
      images: [
        {
          size: 33,
          url: 'https://drscdn.500px.org/photo/1000514214/q%3D80_m%3D2000/v2?sig=838a728a2b1feb4e61d0df0b73981ce870e2fb2e33ec0101fa51fdc98a5a55b5',
        },
        {
          size: 32,
          url: 'https://drscdn.500px.org/photo/1000514214/q%3D80_m%3D2000/v2?sig=838a728a2b1feb4e61d0df0b73981ce870e2fb2e33ec0101fa51fdc98a5a55b5',
        },
      ],
    },
  },
  {
    node: {
      legacyId: '1007253782',
      width: 1500,
      height: 1000,
      images: [
        {
          size: 33,
          url: 'https://drscdn.500px.org/photo/1007253782/q%3D80_m%3D2000/v2?sig=babbf396970bbe252ab0069182b5bf3b9e9ade6fd1d6c43cc489d5e53c62ab81',
        },
        {
          size: 32,
          url: 'https://drscdn.500px.org/photo/1007253782/q%3D80_m%3D2000/v2?sig=babbf396970bbe252ab0069182b5bf3b9e9ade6fd1d6c43cc489d5e53c62ab81',
        },
      ],
    },
  },
  {
    node: {
      legacyId: '1007913754',
      width: 3000,
      height: 2000,
      images: [
        {
          size: 33,
          url: 'https://drscdn.500px.org/photo/1007913754/q%3D80_m%3D2000/v2?sig=24e2836d6cb74787970a2a72aed9a909386ee1c479797d45328e728246bc8df5',
        },
        {
          size: 32,
          url: 'https://drscdn.500px.org/photo/1007913754/q%3D80_m%3D2000/v2?sig=24e2836d6cb74787970a2a72aed9a909386ee1c479797d45328e728246bc8df5',
        },
      ],
    },
  },
  {
    node: {
      legacyId: '1024354991',
      width: 3000,
      height: 2000,
      images: [
        {
          size: 33,
          url: 'https://drscdn.500px.org/photo/1024354991/q%3D80_m%3D2000/v2?sig=7f85b4660847689b40258a3ef4ffccb963f772103f15b5d694248df1c126fa1f',
        },
        {
          size: 32,
          url: 'https://drscdn.500px.org/photo/1024354991/q%3D80_m%3D2000/v2?sig=7f85b4660847689b40258a3ef4ffccb963f772103f15b5d694248df1c126fa1f',
        },
      ],
    },
  },
];

const landscapePhotos = [
  {
    node: {
      legacyId: '1020776409',
      width: 2000,
      height: 3000,
      images: [
        {
          size: 33,
          url: 'https://drscdn.500px.org/photo/1020776409/q%3D80_m%3D2000/v2?sig=274363fcd2f5419285220ae97e96404d9dc891b2679eb52989c13068a048e781',
        },
        {
          size: 32,
          url: 'https://drscdn.500px.org/photo/1020776409/q%3D80_m%3D2000/v2?sig=274363fcd2f5419285220ae97e96404d9dc891b2679eb52989c13068a048e781',
        },
      ],
    },
  },
  {
    node: {
      legacyId: '307907645',
      width: 1500,
      height: 1000,
      images: [
        {
          size: 33,
          url: 'https://drscdn.500px.org/photo/307907645/q%3D80_m%3D2000/v2?sig=552030b1b4238bed13952a55cb33e663dfafff6e3f81de176ce7b8fcb34a8926',
        },
        {
          size: 32,
          url: 'https://drscdn.500px.org/photo/307907645/q%3D80_m%3D2000/v2?sig=552030b1b4238bed13952a55cb33e663dfafff6e3f81de176ce7b8fcb34a8926',
        },
      ],
    },
  },
  {
    node: {
      legacyId: '308134357',
      width: 1500,
      height: 1000,
      images: [
        {
          size: 33,
          url: 'https://drscdn.500px.org/photo/308134357/q%3D80_m%3D2000/v2?sig=3ae09d2c35ace76f6e6b35145b258e8b87ae347fbb3fbff409f9ecf8e362dcc3',
        },
        {
          size: 32,
          url: 'https://drscdn.500px.org/photo/308134357/q%3D80_m%3D2000/v2?sig=3ae09d2c35ace76f6e6b35145b258e8b87ae347fbb3fbff409f9ecf8e362dcc3',
        },
      ],
    },
  },
  {
    node: {
      legacyId: '1007370896',
      width: 1000,
      height: 1500,
      images: [
        {
          size: 33,
          url: 'https://drscdn.500px.org/photo/1007370896/q%3D80_m%3D2000/v2?sig=0391a6ce154a283d550579f423e51f2091b52d37b8ec4f17016a6829682327e8',
        },
        {
          size: 32,
          url: 'https://drscdn.500px.org/photo/1007370896/q%3D80_m%3D2000/v2?sig=0391a6ce154a283d550579f423e51f2091b52d37b8ec4f17016a6829682327e8',
        },
      ],
    },
  },
  {
    node: {
      legacyId: '1010197272',
      width: 2000,
      height: 3000,
      images: [
        {
          size: 33,
          url: 'https://drscdn.500px.org/photo/1010197272/q%3D80_m%3D2000/v2?sig=9fb2e4c03ecd69de271333cbc5264d3144e13efcf859b9c231090fcdb950039b',
        },
        {
          size: 32,
          url: 'https://drscdn.500px.org/photo/1010197272/q%3D80_m%3D2000/v2?sig=9fb2e4c03ecd69de271333cbc5264d3144e13efcf859b9c231090fcdb950039b',
        },
      ],
    },
  },
];

const aerialPhotos = [
  {
    node: {
      legacyId: '305251603',
      width: 1000,
      height: 1500,
      images: [
        {
          size: 33,
          url: 'https://drscdn.500px.org/photo/305251603/q%3D80_m%3D2000/v2?sig=c865c183594e5efcb5b43dd145aadc805a6e2d0c0c5e44afe679d91ae3dec83f',
        },
        {
          size: 32,
          url: 'https://drscdn.500px.org/photo/305251603/q%3D80_m%3D2000/v2?sig=c865c183594e5efcb5b43dd145aadc805a6e2d0c0c5e44afe679d91ae3dec83f',
        },
      ],
    },
  },
  {
    node: {
      legacyId: '308156549',
      width: 1500,
      height: 1000,
      images: [
        {
          size: 33,
          url: 'https://drscdn.500px.org/photo/308156549/q%3D80_m%3D2000/v2?sig=84f9e50b453d745d00509866f6a59acea2566e0b9df1732d7cc80b8e36e1fbf7',
        },
        {
          size: 32,
          url: 'https://drscdn.500px.org/photo/308156549/q%3D80_m%3D2000/v2?sig=84f9e50b453d745d00509866f6a59acea2566e0b9df1732d7cc80b8e36e1fbf7',
        },
      ],
    },
  },
  {
    node: {
      legacyId: '1017014648',
      width: 2000,
      height: 3000,
      images: [
        {
          size: 33,
          url: 'https://drscdn.500px.org/photo/1017014648/q%3D80_m%3D2000/v2?sig=f4d10b8475b2572c98bbef6100b263562129e05314f8bb28ec3cc53862919388',
        },
        {
          size: 32,
          url: 'https://drscdn.500px.org/photo/1017014648/q%3D80_m%3D2000/v2?sig=f4d10b8475b2572c98bbef6100b263562129e05314f8bb28ec3cc53862919388',
        },
      ],
    },
  },
  {
    node: {
      legacyId: '1010728106',
      width: 2599,
      height: 3898,
      images: [
        {
          size: 33,
          url: 'https://drscdn.500px.org/photo/1010728106/q%3D80_m%3D2000/v2?sig=6cadc6804f8864ecb854bef8864d1d76560ffe6ff8f53898ec0a153094e3282e',
        },
        {
          size: 32,
          url: 'https://drscdn.500px.org/photo/1010728106/q%3D80_m%3D2000/v2?sig=6cadc6804f8864ecb854bef8864d1d76560ffe6ff8f53898ec0a153094e3282e',
        },
      ],
    },
  },
];

const folders = [
  {
    id: '1',
    name: 'Travel',
    description: 'Capturing the feelings and emotions of a certain moment is what my travel photography is all about.',
    coverPhoto: {
      legacyId: '1007253782',
      width: 1500,
      height: 1000,
      images: [
        {
          size: 33,
          url: 'https://drscdn.500px.org/photo/1007253782/q%3D80_h%3D600/v2?sig=82acf23227663e3f65206abf342fa8140c8f6369808a6f3d02423f3be8250895',
        },
        {
          size: 32,
          url: 'https://drscdn.500px.org/photo/1007253782/q%3D80_h%3D300/v2?sig=0dcdb031ed499ff19b712e7c5a89d56e1063b92ad8a59c25a0601fcc1b3cdf0f',
        },
      ],
    },
    photos: {
      edges: [...travelPhotos],
    },
  },
  {
    id: '2',
    name: 'Landscape',
    description: 'This folder showcases the beauty of nature. I like to keep my landscape photography natural and clean while also telling the viewer a story.',
    coverPhoto: {
      legacyId: '308134357',
      width: 1500,
      height: 1000,
      images: [
        {
          size: 33,
          url: 'https://drscdn.500px.org/photo/308134357/q%3D80_m%3D2000/v2?sig=3ae09d2c35ace76f6e6b35145b258e8b87ae347fbb3fbff409f9ecf8e362dcc3',
        },
        {
          size: 32,
          url: 'https://drscdn.500px.org/photo/308134357/q%3D80_m%3D2000/v2?sig=3ae09d2c35ace76f6e6b35145b258e8b87ae347fbb3fbff409f9ecf8e362dcc3',
        },
      ],
    },
    photos: {
      edges: [...landscapePhotos],
    },
  },
  {
    id: '3',
    name: 'Aerial',
    description: 'Playing with green and blue tones, my aerial photography is all about capturing the beauty of nature and giving it the respect it deserves.',
    coverPhoto: {
      legacyId: '308156549',
      width: 1500,
      height: 1000,
      images: [
        {
          size: 33,
          url: 'https://drscdn.500px.org/photo/308156549/q%3D80_m%3D2000/v2?sig=84f9e50b453d745d00509866f6a59acea2566e0b9df1732d7cc80b8e36e1fbf7',
        },
        {
          size: 32,
          url: 'https://drscdn.500px.org/photo/308156549/q%3D80_m%3D2000/v2?sig=84f9e50b453d745d00509866f6a59acea2566e0b9df1732d7cc80b8e36e1fbf7',
        },
      ],
    },
    photos: {
      edges: [...aerialPhotos],
    },
  },
];

const portfolio = {
  theme: {
    name: 'ADAMS',
  },
  title: 'Ueli Frischknecht',
  status: 'ACTIVE',
  description: 'Hi there! My name is Ueli, or @runawayueli on Instagram. I was born in St. Gallen, a small town in the german-speaking part of Switzerland. After finishing my education in economics, I went on my first big trip which took me to Peru. A life changing experience. Taking in fresh views and learning about other cultures soon became my new passion and in the same breath I started to fall in love with photography.\n\nToday, many travels and a handful of experiences later, I am working as a professional freelance photographer, specializing in travel, adventure and lifestyle portraiture. During the course of time, I have been fortunate enough to work with tourism organizations and a variety of well-known clients in the travel, hospitality and lifestyle industry worldwide.',
  folders,
  appearance: 'DARK',
  colour: 'ACCENT_BLUE',
  font: 'ALEO',
  coverPhoto: {
    avatar: {
      images: [
        {
          url: 'https://wsk-mweb.oss-cn-hangzhou.aliyuncs.com/ipic/2020-12-09-img.jpg',
        },
      ],
    },
  },
  user: {
    socialMedia: {
      website: null,
      instagram: 'runawayueli',
      facebook: 'uelifrischknechtphoto',
      twitter: 'runawayueli',
    },
    username: 'runawayueli',
    userProfile: {
      country: 'UK',
      city: 'London',
    },
  },
  visibilityOptions: {
    location: 'VISIBLE',
    pxProfile: 'VISIBLE',
    instagram: 'VISIBLE',
    facebook: 'VISIBLE',
    twitter: 'VISIBLE',
  },
};

export default portfolio;
