export const THEMES = {
  MOODIE: 'MOODIE',
  NEWTON: 'NEWTON',
  ADAMS: 'ADAMS',
};

export const FONTS = {
  INTER: 'INTER',
  ALEO: 'ALEO',
  PT_SERIF: 'PT_SERIF',
};

export const MODES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
};
