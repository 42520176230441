import React, { useState, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import theme from 'web-shared-components/components/Themes/theme';

import AppContext from './AppContext';
import { checkWebpSupport } from './lib/helpers';

import Routes from './Routes';
import { setupIntl, getLocale } from './lib/intl';
import GlobalStyle from './AppStyle';

const App = () => {
  const locale = getLocale();
  const [isWebpSupported, setIsWebpSupported] = useState(false);

  useEffect(() => {
    setupIntl();
    checkWebpSupport(isSupported => setIsWebpSupported(isSupported));
  }, []);

  return (
    <IntlProvider {...locale}>
      <AppContext.Provider
        value={{
          webpSupported: isWebpSupported,
        }}
      >
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <GlobalStyle />
            <Routes />
          </BrowserRouter>
        </ThemeProvider>
      </AppContext.Provider>
    </IntlProvider>
  );
};

export default App;
