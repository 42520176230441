/* eslint-disable max-len */

// Note: for convenience ids are legacyIds, but should also be universal in this context
const architecturePhotos = [
  {
    node: {
      id: '303850681',
      legacyId: '303850681',
      name: 'Explosive Skies',
      description: 'The Ambassador Bridge during sunset in Detroit.',
      width: 5556,
      height: 3969,
      images: [{
        size: 33,
        url: 'https://drscdn.500px.org/photo/303850681/q%3D80_m%3D2000/v2?sig=5b8d42b0635c6931db9e73f030fa5a9411ef5903c2b6e1afcc776eb422b5c686',
      },
      {
        size: 32,
        url: 'https://drscdn.500px.org/photo/303850681/q%3D80_m%3D2000/v2?sig=5b8d42b0635c6931db9e73f030fa5a9411ef5903c2b6e1afcc776eb422b5c686',
      }],
    },
  },
  {
    node: {
      id: '292366061',
      legacyId: '292366061',
      name: 'Baker bar Streetcar',
      description: 'Night photography work',
      width: 4090,
      height: 5726,
      images: [{
        size: 33,
        url: 'https://drscdn.500px.org/photo/292366061/q%3D80_m%3D2000/v2?sig=2247042c54872583399c867477d293f43a12f813208715b8948c6be3a50e4dcc',
      },
      {
        size: 32,
        url: 'https://drscdn.500px.org/photo/292366061/q%3D80_m%3D2000/v2?sig=2247042c54872583399c867477d293f43a12f813208715b8948c6be3a50e4dcc',
      }],
    },
  },
  {
    node: {
      id: '251023557',
      legacyId: '251023557',
      name: 'Flying above Detroit',
      description: 'Took a helicopter flight over Detroit',
      width: 5440,
      height: 3627,
      images: [{
        size: 33,
        url: 'https://drscdn.500px.org/photo/251023557/q%3D80_m%3D2000/v2?sig=375cf36a542033d40674f5b763ed7f137f21b8743d19d6b898e3a1f87224d85f',
      },
      {
        size: 32,
        url: 'https://drscdn.500px.org/photo/251023557/q%3D80_m%3D2000/v2?sig=375cf36a542033d40674f5b763ed7f137f21b8743d19d6b898e3a1f87224d85f',
      }],
    },
  },
  {
    node: {
      id: '1000365170',
      legacyId: '1000365170',
      name: 'Luxury Lanes ',
      description: 'Bring your fanciest shoes ',
      width: 5796,
      height: 4479,
      images: [{
        size: 33,
        url: 'https://drscdn.500px.org/photo/1000365170/q%3D80_m%3D2000/v2?sig=8e0fb287aad416dd7c5b83f613a0a14590ea29cd8c319e495410243d9a9a5bc1',
      },
      {
        size: 32,
        url: 'https://drscdn.500px.org/photo/1000365170/q%3D80_m%3D2000/v2?sig=8e0fb287aad416dd7c5b83f613a0a14590ea29cd8c319e495410243d9a9a5bc1',
      }],
    },
  },
  {
    node: {
      id: '301799161',
      legacyId: '301799161',
      name: 'Route 66 Diner',
      description: 'Scenes from historic Route 66',
      width: 4314,
      height: 6040,
      images: [{
        size: 33,
        url: 'https://drscdn.500px.org/photo/301799161/q%3D80_m%3D2000/v2?sig=14b53d57a271116093e6b5890967748b95270daf5c2326196c7b8e1276daacd4',
      },
      {
        size: 32,
        url: 'https://drscdn.500px.org/photo/301799161/q%3D80_m%3D2000/v2?sig=14b53d57a271116093e6b5890967748b95270daf5c2326196c7b8e1276daacd4',
      }],
    },
  },
  {
    node: {
      id: '288850305',
      legacyId: '288850305',
      name: 'Dusk in Detroit',
      description: 'Blue hour in Detroit',
      width: 5305,
      height: 3789,
      images: [{
        size: 33,
        url: 'https://drscdn.500px.org/photo/288850305/q%3D80_m%3D2000/v2?sig=2445f9cd1ec882114f000c6a12b2b9aeeb9fcc0b120466fca40462a7912d6f78',
      },
      {
        size: 32,
        url: 'https://drscdn.500px.org/photo/288850305/q%3D80_m%3D2000/v2?sig=2445f9cd1ec882114f000c6a12b2b9aeeb9fcc0b120466fca40462a7912d6f78',
      }],
    },
  },
  {
    node: {
      id: '255479545',
      legacyId: '255479545',
      name: 'Detroit Castle',
      description: 'G.A.R. building in Detroit',
      width: 2857,
      height: 4286,
      images: [{
        size: 33,
        url: 'https://drscdn.500px.org/photo/255479545/q%3D80_m%3D2000/v2?sig=1e11454f889431bdbae84ff3c0da3ad137cb3ca122ad1e252ce949c064d9a871',
      },
      {
        size: 32,
        url: 'https://drscdn.500px.org/photo/255479545/q%3D80_m%3D2000/v2?sig=1e11454f889431bdbae84ff3c0da3ad137cb3ca122ad1e252ce949c064d9a871',
      }],
    },
  },
  {
    node: {
      id: '289721229',
      legacyId: '289721229',
      name: 'Texturize',
      description: 'First test shots with my new Fujifilm X100F',
      width: 4875,
      height: 3482,
      images: [{
        size: 33,
        url: 'https://drscdn.500px.org/photo/289721229/q%3D80_m%3D2000/v2?sig=46d00e6d8ad2d20adbcbcb5a65351971c00fc42c069aaca07b1b88ba196293c1',
      },
      {
        size: 32,
        url: 'https://drscdn.500px.org/photo/289721229/q%3D80_m%3D2000/v2?sig=46d00e6d8ad2d20adbcbcb5a65351971c00fc42c069aaca07b1b88ba196293c1',
      }],
    },
  },
  {
    node: {
      id: '1009564557',
      legacyId: '1009564557',
      name: 'Bowler-o',
      description: 'Bowlero Lanes ',
      width: 5677,
      height: 4387,
      images: [{
        size: 33,
        url: 'https://drscdn.500px.org/photo/255479545/q%3D80_m%3D2000/v2?sig=1e11454f889431bdbae84ff3c0da3ad137cb3ca122ad1e252ce949c064d9a871',
      },
      {
        size: 32,
        url: 'https://drscdn.500px.org/photo/255479545/q%3D80_m%3D2000/v2?sig=1e11454f889431bdbae84ff3c0da3ad137cb3ca122ad1e252ce949c064d9a871',
      }],
    },
  },
];

const automotivePhotos = [
  {
    node: {
      id: '304791681',
      legacyId: '304791681',
      name: "'68 Fastback",
      description: "Pristine '68 Fastback in a custom \"Lime Gold\" paint job.",
      width: 5749,
      height: 3833,
      images: [{
        size: 33,
        url: 'https://drscdn.500px.org/photo/304791681/q%3D80_m%3D2000/v2?sig=3bbc33de1ee8b0f394dd171ec0f5d4df5654a4fabe3c1f64ca928a6a992d61d9',
      },
      {
        size: 32,
        url: 'https://drscdn.500px.org/photo/304791681/q%3D80_m%3D2000/v2?sig=3bbc33de1ee8b0f394dd171ec0f5d4df5654a4fabe3c1f64ca928a6a992d61d9',
      }],
    },
  },
  {
    node: {
      id: '1007242573',
      legacyId: '1007242573',
      name: 'Over the river and through the woods ',
      description: 'New work for Ford with the 2020 Explorer ',
      width: 6310,
      height: 4208,
      images: [{
        size: 33,
        url: 'https://drscdn.500px.org/photo/1007242573/q%3D80_m%3D2000/v2?sig=399cd47c2402c35781557a0cf028481d9cb98a617eb0f7a2cdaeef49451cf00b',
      },
      {
        size: 32,
        url: 'https://drscdn.500px.org/photo/1007242573/q%3D80_m%3D2000/v2?sig=399cd47c2402c35781557a0cf028481d9cb98a617eb0f7a2cdaeef49451cf00b',
      }],
    },
  },
  {
    node: {
      id: '308631351',
      legacyId: '308631351',
      name: 'Datsun 240z',
      description: '1970 Datsun 240z series 1 in safari gold',
      width: 6066,
      height: 4045,
      images: [{
        size: 33,
        url: 'https://drscdn.500px.org/photo/308631351/q%3D80_m%3D2000/v2?sig=8d41bb04d60e034159f11f7c535439d9001e2a7a7498b8fac2bcf73b0fe10679',
      },
      {
        size: 32,
        url: 'https://drscdn.500px.org/photo/308631351/q%3D80_m%3D2000/v2?sig=8d41bb04d60e034159f11f7c535439d9001e2a7a7498b8fac2bcf73b0fe10679',
      }],
    },
  },
  {
    node: {
      id: '1010291886',
      legacyId: '1010291886',
      name: 'Marigny Neighborhood, New Orleans. ',
      description: 'A resting Lincoln Futura in New Orleans. ',
      width: 5152,
      height: 3981,
      images: [{
        size: 33,
        url: 'https://drscdn.500px.org/photo/1010291886/q%3D80_h%3D600/v2?sig=4bf61a3c30601ec68e80bfbe1596a381b4a0e59ace553c1ee3d44940a22f1a3c',
      },
      {
        size: 32,
        url: 'https://drscdn.500px.org/photo/1010291886/q%3D80_h%3D300/v2?sig=1c3ca1468389b4db09cf1ba2fae84306f70c55ed1b6974c1d4c2c2b7914aabf0',
      }],
    },
  },
  {
    node: {
      id: '281730471',
      legacyId: '281730471',
      name: 'American Rambler',
      description: 'Ironic to find an American Rambler in Iceland',
      width: 5874,
      height: 4196,
      images: [{
        size: 33,
        url: 'https://drscdn.500px.org/photo/281730471/q%3D80_m%3D2000/v2?sig=e7eb4e51547376a226558d4863372e6bc6434152890b38054e32820066088fb2',
      },
      {
        size: 32,
        url: 'https://drscdn.500px.org/photo/281730471/q%3D80_m%3D2000/v2?sig=e7eb4e51547376a226558d4863372e6bc6434152890b38054e32820066088fb2',
      }],
    },
  },
  {
    node: {
      id: '280551947',
      legacyId: '280551947',
      name: "'67 Stang",
      description: 'Some evening fall sunlight and a 1967 Mustang',
      width: 4181,
      height: 5853,
      images: [{
        size: 33,
        url: 'https://drscdn.500px.org/photo/280551947/q%3D80_m%3D2000/v2?sig=8ed9195cb9623268fa895722590078fee301eea9d9598d32557872b34a7eff53',
      },
      {
        size: 32,
        url: 'https://drscdn.500px.org/photo/280551947/q%3D80_m%3D2000/v2?sig=8ed9195cb9623268fa895722590078fee301eea9d9598d32557872b34a7eff53',
      }],
    },
  },
  {
    node: {
      id: '310572045',
      legacyId: '310572045',
      name: 'Fastback',
      description: 'Rear crop of a Mustang fastback',
      width: 3709,
      height: 5563,
      images: [{
        size: 33,
        url: 'https://drscdn.500px.org/photo/310572045/q%3D80_m%3D2000/v2?sig=2243d666ac297808ebf31af0de4a230a6811c8bad1245144d264eae22b35c7c5',
      },
      {
        size: 32,
        url: 'https://drscdn.500px.org/photo/310572045/q%3D80_m%3D2000/v2?sig=2243d666ac297808ebf31af0de4a230a6811c8bad1245144d264eae22b35c7c5',
      }],
    },
  },
  {
    node: {
      id: '1018692062',
      legacyId: '1018692062',
      name: 'Stallion ',
      description: '1967 Ford Mustang Fastback GT ',
      width: 6599,
      height: 4397,
      images: [{
        size: 33,
        url: 'https://drscdn.500px.org/photo/1018692062/q%3D80_m%3D2000/v2?sig=a9da11c09471311151d6d046030b93b039a2b128d4cee06c0a04a2ae645a17c4',
      },
      {
        size: 32,
        url: 'https://drscdn.500px.org/photo/1018692062/q%3D80_m%3D2000/v2?sig=a9da11c09471311151d6d046030b93b039a2b128d4cee06c0a04a2ae645a17c4',
      }],
    },
  },
  {
    node: {
      id: '270966149',
      legacyId: '270966149',
      name: '1966 Ford Bronco Sport',
      description: 'A clean springtime yellow bronco',
      width: 4937,
      height: 3291,
      images: [{
        size: 33,
        url: 'https://drscdn.500px.org/photo/270966149/q%3D80_m%3D2000/v2?sig=d47bb35c453cee53851343a322418850e7763931b25b1770546e02ec2f757133',
      },
      {
        size: 32,
        url: 'https://drscdn.500px.org/photo/270966149/q%3D80_m%3D2000/v2?sig=d47bb35c453cee53851343a322418850e7763931b25b1770546e02ec2f757133',
      }],
    },
  },
];

const foodPhotos = [
  {
    node: {
      id: '268304719',
      legacyId: '268304719',
      name: 'The Tournesol',
      description: 'The Tournesol cocktail, photographed for Candy Bar, inside the Siren Hotel in Detroit.',
      width: 3544,
      height: 5316,
      images: [{
        size: 33,
        url: 'https://drscdn.500px.org/photo/268304719/q%3D80_m%3D2000/v2?sig=91175c80c9f8b9fcdb69591010515559ba5094b3f5e2fa2228c0c6e35f69127b',
      },
      {
        size: 32,
        url: 'https://drscdn.500px.org/photo/268304719/q%3D80_m%3D2000/v2?sig=91175c80c9f8b9fcdb69591010515559ba5094b3f5e2fa2228c0c6e35f69127b',
      }],
    },
  },
  {
    node: {
      id: '142944333',
      legacyId: '142944333',
      name: 'Farm Egg',
      description: null,
      width: 5667,
      height: 3778,
      images: [{
        size: 33,
        url: 'https://drscdn.500px.org/photo/142944333/q%3D80_m%3D2000/v2?sig=5a3696a8d78164941c77ada6cff15e1d01752c193975b01aa724b3ef4216c241',
      },
      {
        size: 32,
        url: 'https://drscdn.500px.org/photo/142944333/q%3D80_m%3D2000/v2?sig=5a3696a8d78164941c77ada6cff15e1d01752c193975b01aa724b3ef4216c241',
      }],
    },
  },
  {
    node: {
      id: '203126939',
      legacyId: '203126939',
      name: 'Croque Madame',
      description: 'Croque Madame at La Bohemé Cafe in Detroit',
      width: 4480,
      height: 6720,
      images: [{
        size: 33,
        url: 'https://drscdn.500px.org/photo/203126939/q%3D80_m%3D2000/v2?sig=54e5a9cd39d0bcbb111fa1e3edfccf7ff86d682bbd143b49442bade676c5ee03',
      },
      {
        size: 32,
        url: 'https://drscdn.500px.org/photo/203126939/q%3D80_m%3D2000/v2?sig=54e5a9cd39d0bcbb111fa1e3edfccf7ff86d682bbd143b49442bade676c5ee03',
      }],
    },
  },
  {
    node: {
      id: '171640107',
      legacyId: '171640107',
      name: 'Breakfast in bed',
      description: 'Breakfast in bed',
      width: 3840,
      height: 5760,
      images: [{
        size: 33,
        url: 'https://drscdn.500px.org/photo/171640107/q%3D80_m%3D2000/v2?sig=5867da48c4bc61afd6bcbb76bc68ecbaf975755e73dbde74133ac740b3760713',
      },
      {
        size: 32,
        url: 'https://drscdn.500px.org/photo/171640107/q%3D80_m%3D2000/v2?sig=5867da48c4bc61afd6bcbb76bc68ecbaf975755e73dbde74133ac740b3760713',
      }],
    },
  },
  {
    node: {
      id: '146273647',
      legacyId: '146273647',
      name: 'Pan Fried Trout',
      description: 'Pan fried trout',
      width: 5278,
      height: 3519,
      images: [{
        size: 33,
        url: 'https://drscdn.500px.org/photo/146273647/q%3D80_m%3D2000/v2?sig=d36e56543cf63c7d38e615d3d547d90565e1a77126b53736a9057095314c8445',
      },
      {
        size: 32,
        url: 'https://drscdn.500px.org/photo/146273647/q%3D80_m%3D2000/v2?sig=d36e56543cf63c7d38e615d3d547d90565e1a77126b53736a9057095314c8445',
      }],
    },
  },
  {
    node: {
      id: '142944043',
      legacyId: '142944043',
      name: 'Pour up',
      description: null,
      width: 3840,
      height: 5760,
      images: [{
        size: 33,
        url: 'https://drscdn.500px.org/photo/142944043/q%3D80_m%3D2000/v2?sig=fc1ab16724f6fc7031c84be0f17c4e1e6d8d25e85cc0291e7dd317ef60acbd8b',
      },
      {
        size: 32,
        url: 'https://drscdn.500px.org/photo/142944043/q%3D80_m%3D2000/v2?sig=fc1ab16724f6fc7031c84be0f17c4e1e6d8d25e85cc0291e7dd317ef60acbd8b',
      }],
    },
  },
];

const interiorPhotos = [
  {
    node: {
      id: '174870191',
      legacyId: '174870191',
      name: 'The Royce Detroit',
      description: 'The Royce: Wine bar',
      width: 3840,
      height: 5760,
      images: [{
        size: 33,
        url: 'https://drscdn.500px.org/photo/174870191/q%3D80_m%3D2000/v2?sig=b17865faa82b50ea9511a34e8b30621c4a49808f6b1912c7932e7a16ab85541f',
      },
      {
        size: 32,
        url: 'https://drscdn.500px.org/photo/174870191/q%3D80_m%3D2000/v2?sig=b17865faa82b50ea9511a34e8b30621c4a49808f6b1912c7932e7a16ab85541f',
      }],
    },
  },
  {
    node: {
      id: '174870449',
      legacyId: '174870449',
      name: 'The Royce Detroit 2',
      description: 'The Royce: Wine bar',
      width: 3771,
      height: 5657,
      images: [{
        size: 33,
        url: 'https://drscdn.500px.org/photo/174870449/q%3D80_m%3D2000/v2?sig=d4cf08469bcfa87f0e6d7d0239d815b7a3e11dbf8017febd8a73bf2ac858b9a1',
      },
      {
        size: 32,
        url: 'https://drscdn.500px.org/photo/174870449/q%3D80_m%3D2000/v2?sig=d4cf08469bcfa87f0e6d7d0239d815b7a3e11dbf8017febd8a73bf2ac858b9a1',
      }],
    },
  },
  {
    node: {
      id: '216305583',
      legacyId: '216305583',
      name: '+',
      description: 'Harless Flea in Bay City, MI.',
      width: 6134,
      height: 4089,
      images: [{
        size: 33,
        url: 'https://drscdn.500px.org/photo/216305583/q%3D80_m%3D2000/v2?sig=724e6e3b1657697cfd2814ee4dd2248e3efd8948a2101698ee992780330e8e3a',
      },
      {
        size: 32,
        url: 'https://drscdn.500px.org/photo/216305583/q%3D80_m%3D2000/v2?sig=724e6e3b1657697cfd2814ee4dd2248e3efd8948a2101698ee992780330e8e3a',
      }],
    },
  },
  {
    node: {
      id: '185974273',
      legacyId: '185974273',
      name: 'Spending the weekend here',
      description: 'Cozy cabin corner',
      width: 5760,
      height: 3840,
      images: [{
        size: 33,
        url: 'https://drscdn.500px.org/photo/185974273/q%3D80_m%3D2000/v2?sig=73f48c1ec952b8cc17cd2677b8435591a34248f5eb90091570e57ab9da64fd58',
      },
      {
        size: 32,
        url: 'https://drscdn.500px.org/photo/185974273/q%3D80_m%3D2000/v2?sig=73f48c1ec952b8cc17cd2677b8435591a34248f5eb90091570e57ab9da64fd58',
      }],
    },
  },
];

const folders = [
  {
    id: '4',
    name: 'Architecture',
    photos: {
      edges: [...architecturePhotos],
    },
    description: 'A mix of classic, conceptual, and colorful architecture.',
  },
  {
    id: '2',
    name: 'Automotive',
    photos: {
      edges: [...automotivePhotos],
    },
    description: 'This folder highlights the work I’ve done for some of my top automotive clients. I play with artistic concepts like form, shape, line, tone, color, pattern, light, and shadows to create unique images that speak to the viewer.',
  },
  {
    id: '3',
    name: 'Food and Drinks',
    photos: {
      edges: [...foodPhotos],
    },
    description: 'My food and drink photography is modern and minimalist. I play with lights, depth of field, colors, style, and mood to ensure my images are clean and visually appealing.',
  },
  {
    id: '5',
    name: 'Interior',
    photos: {
      edges: [...interiorPhotos],
    },
    description: 'My interior photography ranges from homes to commercial environments. I focus on the unique features and functions of the space to bring them to life in the photo.',
  },
];

const portfolio = {
  theme: {
    name: 'MOODIE',
  },
  title: 'Hayden Scott',
  status: 'ACTIVE',
  description: 'Hayden Stinebaugh is a commercial and editorial photographer based in Detroit. He works for big name clients and brands like Ford, Shinola, Lululemon, and others. When not shooting professionally, you can find him taking photos around Detroit, or traveling to pursue his favorite realm of photography: landscapes.',
  folders,
  appearance: 'LIGHT',
  font: 'INTER',
  colour: 'ACCENT_ASH',
  coverPhoto: {
    avatar: {
      images: [
        {
          url: 'https://wsk-mweb.oss-cn-hangzhou.aliyuncs.com/ipic/2020-11-04-img.png',
        },
      ],
    },
  },
  user: {
    socialMedia: {
      website: null,
      instagram: 'hayden__scott',
      facebook: 'hsphotodetroit',
      twitter: null,
    },
    username: 'haydenscott',
    userProfile: {
      country: 'UK',
      city: 'London',
    },
  },
  visibilityOptions: {
    location: 'VISIBLE',
    pxProfile: 'VISIBLE',
    instagram: 'VISIBLE',
    facebook: 'VISIBLE',
    twitter: 'HIDDEN',
  },
};

export default portfolio;
