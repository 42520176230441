import React from 'react';
import { QueryRenderer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';

import Loading from 'web-shared-components/components/Loading';
import NotFound from 'web-shared-components/components/PortfolioTemplates/common/NotFound';
import PortfolioMetaInfo from 'web-shared-components/components/MetaInfo/PortfolioMetaInfo';
// eslint-disable-next-line max-len
import UnPublishSite from 'web-shared-components/components/PortfolioTemplates/common/UnPublishSite';

import BlackIcon from '../assets/black-icon.png';
import environment from '../lib/graphqlEnvironment';
import { GlobalStyle, theme } from '../lib/theme';
import { getQueryParamValue } from '../lib/helpers';
import Portfolio from './Portfolio';

const PortfolioQueryRenderer = ({ domain }) => {
  const previewToken = getQueryParamValue('previewToken');
  // const description = `domain is ${domain}, href is ${window.location.href}`;

  return (
    <QueryRenderer
      environment={environment}
      query={graphql`
        query PortfolioQueryRendererQuery (
          $domain: String!
        ) {
          portfolio: getPortfolioByDomain(domain: $domain) {
            id
            legacyId
            status
            title
            description
            email
            font
            colour
            appearance
            deactivatedMessage
            socialMedia {
              twitter
              instagram
              facebook
            }
            location {
              city
              country
            }
            user {
              username
            }
            termsOfUse {
              ...on PortfolioBasicLegalSettings {
                type
              }
              ...on PortfolioCustomLegalSettings {
                url
              }
            }
            privacyPolicy {
              ...on PortfolioBasicLegalSettings {
                type
              }
              ...on PortfolioCustomLegalSettings {
                url
              }
            }
            coverPhoto {
              ...on PortfolioCoverImage {
                images {
                  legacyId
                  url
                }
              }
              ...on PortfolioCoverAvatar {
                avatar {
                  images(sizes: [COVER]) {
                    url
                  }
                }
              }
            }
            theme {
              name
              description
              imageUrls {
                size
                url
              }
            }
            visibilityOptions {
              location
              pxProfile
              instagram
              facebook
              twitter
            }
            folders {
              id
              name
              description
              coverPhoto {
                legacyId
                name
                width
                height
                images(sizes: [21, 35]) {
                  jpegUrl
                  webpUrl
                  url
                }
              }
              photos(first: 100) {
                edges {
                  node {
                    id
                    legacyId
                    name
                    description
                    width
                    height
                    images(sizes: [36, 35, 34, 33, 32]) {
                      size
                      url
                      id
                    }
                  }
                }
                totalCount
              }
            }
            analyticsCode
          }
        }
      `}
      variables={{
        domain,
      }}
      render={({ error, props }) => {
        if (props) {
          /* eslint-disable react/prop-types */
          const { portfolio } = props;
          if (portfolio) {
            if (portfolio.status === 'ACTIVE' || previewToken) {
              return (
                <ThemeProvider theme={theme(portfolio.font, portfolio.appearance)}>
                  <GlobalStyle />
                  <Portfolio portfolio={portfolio} domain={domain} type="site" />
                </ThemeProvider>
              );
            }
            return (
              <UnPublishSite
                cover={portfolio.coverPhoto}
                title={portfolio.title}
                deactivatedMessage={portfolio.deactivatedMessage}
                socialMedia={portfolio.user.socialMedia}
                visibilityOptions={portfolio.visibilityOptions}
              />
            );
          }
          return (
            <>
              <PortfolioMetaInfo
                title="500px Portfolio / Not Found"
                description=""
                icon={BlackIcon}
              />
              <NotFound />
            </>
          );
        }
        if (error) {
          return (
            <>
              <PortfolioMetaInfo
                title="500px Portfolio / Not Found"
                description=""
                icon={BlackIcon}
              />
              <NotFound />
            </>
          );
        }
        return <Loading />;
      }}
    />
  );
};

PortfolioQueryRenderer.propTypes = {
  domain: PropTypes.string.isRequired,
};

export default PortfolioQueryRenderer;
